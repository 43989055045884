<template lang="pug">
.uploader
  .uploader-show(
    v-if="value || viewOnly"
  )
    img(
      :src="value"
      v-viewer
    )
    .actions(
      v-if="!viewOnly"
    )
      icon(
        @click="handleRemove"
        type="ios-trash-outline"
      )

  .uploader-choose(
    v-else
  )
    Upload(
      action="#",
      :accept="uploadAccept"
      :before-upload="handleBeforeUpload"
      :show-upload-list="false"
    )
      Icon(
        type="ios-add"
        style="line-height: 0"
      )

  .uploader-progress(
    v-if="showProgress"
  )
    Progress(
      :percent="progressPercent"
      :stroke-width="2"
    )
    
</template>

<script>
const acceptTypes = ["jpg", "png", "jpeg"];
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showProgress: false,
      progressPercent: 0,
    };
  },
  computed: {
    acceptTypes() {
      return;
    },
    uploadAccept() {
      return acceptTypes.reduce((s, i) => `${s}.${i},`, "");
    },
  },
  methods: {
    readFile(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadstart = () => {
        this.showProgress = true;
      };

      reader.onprogress = (e) => {
        this.progressPercent = Math.round((e.loaded / e.total) * 100);
      };

      reader.onload = () => {
        this.$emit("input", reader.result);
        this.showProgress = false;
        this.progressPercent = 0;
      };
    },
    handleBeforeUpload(file) {
      const fileExt = file.name.split(".").pop().toLocaleLowerCase();

      if (acceptTypes.some((t) => fileExt === t)) {
        this.readFile(file);
      } else {
        this.$Notice.warning({
          title: "文件类型错误",
          desc: "附件格式不正确，请选择图片格式！",
        });
      }
    },

    handleRemove() {
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="less" scoped>
.uploader {
  position: relative;

  &-show,
  &-choose {
    width: 60px;
    height: 60px;
  }

  &-progress {
    position: absolute;
    width: 100%;
    height: 32px;
  }

  &-show {
    position: relative;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }

    .actions {
      height: 16px;
      z-index: 999;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      /deep/ .ivu-icon {
        cursor: pointer;
      }
    }
  }

  &-choose {
    border: 1px dashed #2988f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /deep/.ivu-upload {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
