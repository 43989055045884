var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uploader" }, [
    _vm.value || _vm.viewOnly
      ? _c("div", { staticClass: "uploader-show" }, [
          _c("img", {
            directives: [{ name: "viewer", rawName: "v-viewer" }],
            attrs: { src: _vm.value },
          }),
          !_vm.viewOnly
            ? _c(
                "div",
                { staticClass: "actions" },
                [
                  _c("icon", {
                    attrs: { type: "ios-trash-outline" },
                    on: { click: _vm.handleRemove },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      : _c(
          "div",
          { staticClass: "uploader-choose" },
          [
            _c(
              "Upload",
              {
                attrs: {
                  action: "#",
                  accept: _vm.uploadAccept,
                  "before-upload": _vm.handleBeforeUpload,
                  "show-upload-list": false,
                },
              },
              [
                _c("Icon", {
                  staticStyle: { "line-height": "0" },
                  attrs: { type: "ios-add" },
                }),
              ],
              1
            ),
          ],
          1
        ),
    _vm.showProgress
      ? _c(
          "div",
          { staticClass: "uploader-progress" },
          [
            _c("Progress", {
              attrs: { percent: _vm.progressPercent, "stroke-width": 2 },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }